import React from 'react';

const Spinner = () => {
    return (
        <div className="spinner">
            <span className="visually-hidden">
                Sending Email
            </span>
        </div>
    );
};

export default Spinner;
