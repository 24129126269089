
import React from 'react';

const SuccessSVG = ({className}) => {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c-0.007-6.624-5.375-11.993-12-12zM12 22.286c-5.681 0-10.286-4.605-10.286-10.286s4.605-10.286 10.286-10.286 10.286 4.605 10.286 10.286c-0.006 5.678-4.608 10.28-10.286 10.286z"></path>
            <path d="M18.585 7.108c-0.332-0.321-0.859-0.321-1.191 0v0l-7.965 7.965-2.823-2.823c-0.329-0.341-0.872-0.35-1.212-0.021s-0.35 0.872-0.021 1.212c0.007 0.007 0.014 0.014 0.021 0.021l3.429 3.429c0.335 0.335 0.877 0.335 1.212 0l8.571-8.571c0.329-0.34 0.319-0.883-0.021-1.212z"></path>
        </svg>
    );
}

export default SuccessSVG;
