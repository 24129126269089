import React, {useState} from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import WarningSVG from '../components/WarningSVG';
import SuccessSVG from '../components/SuccessSVG';
import Spinner from '../components/Spinner';

const Contact = () => {

    const [clientName, setClientName] = useState('');
    const [isClientNameEmpty, setIsClientNameEmpty] = useState(false);

    const [email, setEmail] = useState('');
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);

    const [subject, setSubject] = useState('');

    const [message, setMessage] = useState('');
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);

    const [isEmailSending, setIsEmailSending] = useState(false);

    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);

    const handleClientNameInputChange = e => {
        e.preventDefault();
        setClientName(e.target.value);
    }

    const handleEmailInputChange = e => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleSubjectInputChange = e => {
        e.preventDefault();
        setSubject(e.target.value);
    }

    const handleMessageInputChange = e => {
        e.preventDefault();
        setMessage(e.target.value);
    }

    const validateForm = () => {
        setEmailFailed(false);
        setEmailSent(false);
        
        if (clientName === '') {
            setIsClientNameEmpty(true);
        } else {
            setIsClientNameEmpty(false);
        }

        if (email === '') {
            setIsEmailEmpty(true);
        } else {
            setIsEmailEmpty(false);
        }

        if (message === '') {
            setIsMessageEmpty(true);
        } else {
            setIsMessageEmpty(false);
        }
    }

    const sendEmail = e => {
        e.preventDefault();
        setIsEmailSending(true);

        const emailData = {
            name: clientName,
            email: email,
            subject: subject,
            message: message
        };

        axios.post('https://glendalebuilders.herokuapp.com/contact', emailData)
            .then(res => {
                if (res.status === 200) {
                    setEmailSent(true);
                    setEmailFailed(false);
                    setIsEmailSending(false);
                    setClientName('');
                    setEmail('');
                    setSubject('');
                    setMessage('');
                } else {
                    setEmailFailed(true);
                    setEmailSent(false);
                    setIsEmailSending(false);
                }

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            })
            .catch(err => {
                setEmailFailed(true);
                setEmailSent(false);
                setIsEmailSending(false);

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
    }

    return (
        <Layout>
            <SEO
                title="Contact Glendale Builders"
                description="Contact Glendale Builders and we can start talking about your dream home today! Send us an email or give us a call and we'll get back to you as soon as possible."
            />
            <section className="main-contact" aria-label="Contact Glendale Builders">
                <div className="main-contact__container">
                    <div className="main-contact__column main-contact__column--small">
                        <h2 className="main-contact__title">
                            Like What You See or Want To Learn More? <span className="main-contact__span">Let Us Know How We Can Help</span>.
                        </h2>
                    </div>
                    <div className="main-contact__column">
                        <div className={emailFailed ? "main-contact__error main-contact__error--active" : "main-contact__error"}>
                            <span className="main-contact__failed">
                                <WarningSVG className="main-contact__svg"/>
                                There was an error while sending your email. Please check that the
                                form is filled out correctly and try again.
                            </span>
                        </div>
                        <div className={emailSent ? "main-contact__success main-contact__success--active" : "main-contact__success"}>
                            <SuccessSVG className="main-contact__svg" />
                            <span className="main-contact__sent">
                                Your email was successfully sent! Thanks for getting in touch with us
                                and we'll get back to you as soon as possible!
                            </span>
                        </div>
                        <form onSubmit={sendEmail} className="main-contact__form">
                            <div className="main-contact__wrapper">
                                <label htmlFor="client-name" className="main-contact__label">
                                    Enter your Name
                                    <input type="text" className={isClientNameEmpty ? "main-contact__input main-contact__input--active" : "main-contact__input"} name="clientName" value={clientName} onChange={handleClientNameInputChange} id="client-name" required aria-required="true"/>
                                </label>
                                <div className={isClientNameEmpty ? "main-contact__error main-contact__error--active" : "main-contact__error"}>
                                    <span className="main-contact__failed">
                                        <WarningSVG className="main-contact__svg"/>
                                        'Name' is required. Please enter a valid value.
                                    </span>
                                </div>
                            </div>
                            <div className="main-contact__wrapper">
                                <label htmlFor="client-email" className="main-contact__label">
                                    Enter your Email Address
                                    <input type="email" className={isEmailEmpty ? "main-contact__input main-contact__input--active" : "main-contact__input"} name="email" value={email} onChange={handleEmailInputChange} id="client-email" required aria-required="true"/>
                                </label>
                                <div className={isEmailEmpty ? "main-contact__error main-contact__error--active" : "main-contact__error"}>
                                    <span className="main-contact__failed">
                                        <WarningSVG className="main-contact__svg"/>
                                        'Email Address' is required. Please enter a valid value.
                                    </span>
                                </div>
                            </div>
                            <div className="main-contact__wrapper">
                                <label htmlFor="email-subject" className="main-contact__label">
                                    Enter a Subject (Optional)
                                    <input type="text" className="main-contact__input" name="subject" value={subject} onChange={handleSubjectInputChange} id="email-subject"/>
                                </label>
                            </div>
                            <div className="main-contact__wrapper">
                                <label htmlFor="email-message" className="main-contact__label">
                                    Enter your Message
                                    <textarea className={isMessageEmpty ? "main-contact__textarea main-contact__textarea--active" : "main-contact__textarea"} name="message" value={message} onChange={handleMessageInputChange} id="email-message" rows="8" required aria-required="true"/>
                                </label>
                                <div className={isMessageEmpty ? "main-contact__error main-contact__error--active" : "main-contact__error"}>
                                    <span className="main-contact__failed">
                                        <WarningSVG className="main-contact__svg"/>
                                        'Message' is required. Please enter a valid value.
                                    </span>
                                </div>
                            </div>
                            {isEmailSending ? 
                                (
                                    <Spinner />
                                ) : 
                                (
                                    <button type="submit" className="main-contact__submit" onClick={validateForm}>
                                        Send
                                    </button>
                                )
                            }
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Contact;
