
import React from 'react';

const WarningSVG = ({className}) => {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path d="M23.501 17.998l-8.478-15.585c-1.362-2.293-4.683-2.296-6.047 0l-8.478 15.585c-1.392 2.343 0.293 5.311 3.023 5.311h16.956c2.727 0 4.416-2.965 3.023-5.311zM12 20.496c-0.775 0-1.406-0.631-1.406-1.406s0.631-1.406 1.406-1.406c0.775 0 1.406 0.631 1.406 1.406s-0.631 1.406-1.406 1.406zM13.406 14.871c0 0.775-0.631 1.406-1.406 1.406s-1.406-0.631-1.406-1.406v-7.031c0-0.775 0.631-1.406 1.406-1.406s1.406 0.631 1.406 1.406v7.031z"></path>
        </svg>
    );
}

export default WarningSVG;
